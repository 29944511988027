<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="tech-service-all" no-body>

    <template v-if="edit">
      <Form/>
    </template>

    <template v-else-if="docs.classificator">
      <Classificator/>
    </template>

    <template v-else-if="docs.fuel">
      <Fuel/>
    </template>

    <template v-else-if="docs.info">
      <Info/>
    </template>

    <template v-else-if="docs.financial">
      <Financial/>
    </template>

    <template v-else>
      <b-row class="pageContent">
        <b-col md="12">
          <ag-grid-vue
              @gridReady="onGridReady"
              ref="agGridTable"
              :localeText="localization"
              :components="components"
              :gridOptions="gridOptions"
              :class="table_class"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="checkups"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :paginationPageSize="50"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl">
          </ag-grid-vue>
          <b-card-body class="table-footer d-flex justify-content-between flex-wrap pt-0 pb-0 align-items-center">

            <!-- page length -->
            <strong class="black">
              {{ pagination.total }} արդյունք
            </strong>

            <!-- pagination -->
            <div>
              <b-pagination
                  @input="changePage"
                  v-model="pagination.current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.count"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </template>

    <b-modal
        id="modal-add-c1-number"
        :no-close-on-backdrop="true"
        centered
        size="sm"
        title="Կտրոնի կրկնօրինակ"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table b-table table-striped table-hover"
                   id="__BVID__139"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Կտրոնի համար</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="num in checkupNumbers" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ num.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ num.number }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="modal-checkup-company-info"
        centered
        title="Կազմակերպություն"
        :is-valid="false"
    >

      <div v-if="companyInfo" class="companyInfo">
        <div class="infoItem">
          <div class="label">Կազմակերպություն:</div>
          <div class="value">{{companyInfo.company.name}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Կազմակերպության հասցե:</div>
          <div class="value">{{companyInfo.company.region}} {{companyInfo.company.county}} {{companyInfo.company.street}} {{companyInfo.company.address}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Մեքենա:</div>
          <div class="value">{{companyInfo.car}} {{companyInfo.car_model}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Կտրոնի համար:</div>
          <div class="value">{{ (companyInfo.CheckNo) ? companyInfo.companyInfo : '-' }}</div>
        </div>
        <div class="infoItem">
          <div class="label">Տնօրինող:</div>
          <div class="value">{{companyInfo.ownerStatus.Owner}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Հասցե:</div>
          <div class="value">{{companyInfo.region}} {{companyInfo.county}} {{companyInfo.street}} {{companyInfo.address}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Համարանիշ:</div>
          <div class="value">{{companyInfo.CarRegNo}} {{ (companyInfo.YellowCarRegNo) ? `(${companyInfo.YellowCarRegNo})` : ''}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Փաստաթուղթ:</div>
          <div class="value">{{companyInfo.documentNumber}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Նույնականացման VIN կոդ:</div>
          <div class="value">{{companyInfo.VIN}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Հաջորդ ՏԶ:</div>
          <div class="value">{{nextCheckupName(companyInfo.NextCheckup)}}</div>
        </div>
        <div class="infoItem">
          <div class="label">Թափքի տեսակ:</div>
          <div class="value">{{companyInfo.body}}</div>
        </div>
        <!--        <div class="infoItem">-->
        <!--          <div class="label">Գույն:</div>-->
        <!--          <div class="value">{{companyInfo.color}}</div>-->
        <!--        </div>-->
        <div class="infoItem">
          <div class="label">Եզրակացություն:</div>
          <div class="value">{{ (companyInfo.Opinion) ? 'Սարքին է' : 'Անսարք է' }}</div>
        </div>
        <div class="infoItem">
          <div class="label">Կրկնակի Տ․Զ․:</div>
          <div class="value">{{ (companyInfo.DoubleChecked) ? 'Այո' : 'Ոչ' }}</div>
        </div>
      </div>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="modal-checkup-history"
        :no-close-on-backdrop="true"
        centered
        size="lg"
        class="modal-responsive"
        title="Պատմություն"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table mb-2 b-table table-striped table-hover"
                   id="__BVID__140"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Մոդերատոր</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Գործողույուն</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նախկին տվյալ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նոր տվյալ</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="history in checkupHistory" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ history.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ (history.user) ? history.user.name : '' }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.note }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.old }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.new }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

  </b-card-code>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import companyModule from '@/store/companies/companyModule'
import store from '@/store'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Financial from '@/views/pages/tech-service/docs/Financial'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BAlert,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import Form from '@/views/pages/tech-service/Form'
import CellRendererOpinion from '@/views/pages/tech-service/columns/CellRendererOpinion'
import CellRendererChanges from '@/views/pages/tech-service/columns/CellRendererChanges'
import CellRendererCanEdit from '@/views/pages/tech-service/columns/CellRendererCanEdit'
import CellRendererEdit from '@/views/pages/tech-service/columns/CellRendererEdit'
import AG_GRID_LOCALE_HY from '@core/global/ag-grid-hy'
import vSelect from 'vue-select'
import Classificator from '@/views/pages/tech-service/docs/Classificator'
import Fuel from '@/views/pages/tech-service/docs/Fuel'
import Info from '@/views/pages/tech-service/docs/Info'
import carsModule from '@/store/cars/cars'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  name: 'co-payments',

  components: {
    vSelect,
    BCardCode,
    AgGridVue,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BTable,
    BAlert,
    BDropdown,
    BDropdownItem,
    Form,
    CellRendererOpinion,
    CellRendererChanges,
    CellRendererCanEdit,
    CellRendererEdit,
    Classificator,
    Fuel,
    Info,
    Financial,
  },

  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      gridOptions: {
        getRowStyle: (params) => {
          if (params.data.CheckNoEdited) {
            return { 'background-color': '#ffc107' }
          }
          if (params.data.deleted) {
            return { 'background-color': '#afafaf' }
          }
        }
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      components: null,
      opinions: [
        {
          label: 'Սարքին',
          id: true
        },
        {
          label: 'Անսարք',
          id: false
        }
      ]
    }
  },

  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    let todayStart = new Date()
    todayStart.setHours(0, 0, 0, 0)

    let todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 59)

    const initialFilters = {
      Opinion: true,
      startDate: todayStart,
      endDate: todayEnd,
      search: '',
      company: '',
      fields: {}
    }

    return {
      skin,
      isDark,
      initialFilters
    }
  },

  computed: {

    checkupHistory() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupHistory
    },

    companyInfo() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].companyInfo
    },

    checkupNumbers() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupNumbers
    },

    search_filters() {
      return [
        {
          label: 'Գրանցման համարանիշի',
          id: 'CarRegNo'
        },
        {
          label: 'Գրանցման փաստաթղթի',
          id: 'documentNumber'
        },
        {
          label: 'Կտրոնի համարի',
          id: 'CheckNo'
        },
        {
          label: 'Նույնականացման VIN կոդի',
          id: 'VIN'
        },
        // {
        //   label: 'Շարժիչի համարի',
        //   id: 'EngineNo'
        // },
        // {
        //   label: 'Հենասարքի համարի',
        //   id: 'TrilerNo'
        // },
        // {
        //   label: 'Թափքի համարի',
        //   id: 'BodyNo'
        // }
      ]
    },

    techServiceColumns () {
      return this.$store.state.techServiceColumns
    },

    columnStatuses() {
      return this.$store.state.columnStatuses
    },

    isHide () {
      return (id) => {
        let index = this.columnStatuses.findIndex((e) => e.id === id)
        if (index >= 0) {
          return this.columnStatuses[index].hide
        }
        return true
      }
    },

    columnDefs() {
      return [
        {
          headerName: 'Ստեղծվել է',
          id: '002',
          hide: this.isHide('002'),
          field: 'createdAt',
          filter: true,
          floatingFilterComponent: 'customDateFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'createdAt',
            className: ''
          },
          width: 150,
          valueFormatter: this.dateFormatter
        },
        {
          headerName: 'Տնօրինող',
          id: '021',
          hide: this.isHide('021'),
          field: 'ownerStatus.Owner',
          width: 200,
          filter: true,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'ownerStatus.Owner',
            className: ''
          },
        },
        {
          headerName: 'Մեքենա',
          id: '033',
          hide: this.isHide('033'),
          field: 'car',
          valueFormatter: this.carFormatter,
          filter: true,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'car',
            className: ''
          },
          width: 210,
        },
        {
          headerName: 'Համարանիշ',
          id: '037',
          hide: this.isHide('037'),
          field: 'CarRegNo',
          filter: true,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'CarRegNo',
            className: 'text-uppercase'
          },
          width: 210,
          valueFormatter: this.carRegNoFormatter
        },
        {
          headerName: 'Գումար',
          id: '062',
          hide: this.isHide('062'),
          field: 'EkoSum',
          filter: true,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'EkoSum',
            className: ''
          },
          width: 210,
        },
      ]
    },

    company_operators() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company_operators.map(e => ({
        label: e.name,
        value: e._id,
      }))
    },

    edit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].edit
    },

    bodies() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].bodies
    },

    docs() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].docs
    },

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkups
    },

    pagination() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].pagination
    },

    filters: {
      get() {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].filters
      },
      set(data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_INITIAL_FILTERS', data)
      }
    },

    table_class() {
      return (this.isDark) ? 'tech-service-table ag-theme-alpine-dark w-100 ag-grid-table' : 'tech-service-table ag-theme-alpine w-100 ag-grid-table'
    },

    localization() {
      return AG_GRID_LOCALE_HY
    },

    companies() {
      return [
        {
          label: 'Կազմակերպություն',
          value: null,
          disabled: true
        },
        ...this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
          label: e.name,
          id: e._id
        }))
      ]
    },

    ownerStatuses() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].ownerStatuses
    },

    nextCheckups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].nextCheckups
    },

  },

  beforeMount() {
    this.components = {
      customFloatingFilter: this.getCustomFloatingFilterComponent(),
      customDateFloatingFilter: this.getCustomDateFloatingFilterComponent(),
      customSelectFloatingFilter: this.getCustomSelectFloatingFilterComponent(),
    }
  },

  mounted() {
  },

  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    this.getCheckups()
    this.getOperators()
    this.getCompanies()
    this.getBodies()
    // this.filters = this.initialFilters
    // this.filters.type = 'CarRegNo'
  },

  destroyed() {
    this.filters = this.initialFilters
  },

  methods: {

    changeSearchTypeMobile(id) {
      this.filters.type = id
      this.getCheckups()
    },

    getOperators() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getOperators')
    },

    getBodies() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getBodies')
    },

    getCompanies() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getViewerCompanies')
    },

    onGridReady(params) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_API', params.api)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_COLUMN_API', params.columnApi)
    },

    getCheckups() {
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getViewerCheckups', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changePage(page) {
      this.pagination.current_page = page
      this.getCheckups()
    },

    disableSymbol(e) {
      if (!(/^[*a-zA-Z0-9ղցվա]*$/.test(e.key))) {
        e.preventDefault()
      }
    },

    changeCompany() {
      this.getCheckups()
    },

    searchCheckup() {
      this.getCheckups()
    },

    ownerStatusFormatter(params) {
      const index = this.ownerStatuses.findIndex((i) => i.id === params.value)
      if (index >= 0) {
        return this.ownerStatuses[index].title
      }
      return ''
    },

    companyAddressFormatter(params) {
      return params.value.region + ' ' + params.value.county + ' ' + params.value.street + ' ' + params.value.address
    },

    carFormatter(params) {
      return params.data.car + ' ' + params.data.car_model
    },

    carRegNoFormatter(params) {
      return `${params.data.CarRegNo} ${(params.data.YellowCarRegNo) ? `(${params.data.YellowCarRegNo.toLocaleUpperCase()})` : ''}`
    },

    bodyNameFormatter(params) {
      if (params.value) {
        return params.value
      } else {
        let bodyIndex = this.bodies.findIndex((b) => b.id === params.data.body_id)
        if (bodyIndex >= 0) {
          return this.bodies[bodyIndex].name
        }
      }
    },

    uppercaseFormatter(params) {
      return (params.value) ? params.value.toLocaleUpperCase() : params.value
    },

    dateFormatter(params) {
      return this.$options.filters.formatDate(params.value)
    },

    dayFormatter(params) {
      return this.$options.filters.formatDay(params.value)
    },

    nextCheckupFormatter(params) {
      const index = this.nextCheckups.findIndex((e) => e.value == params.value)
      if (index >= 0) {
        return this.nextCheckups[index].label
      }
      return params.value
    },

    nextCheckupName(value) {
      const index = this.nextCheckups.findIndex((e) => e.value == value)
      if (index >= 0) {
        return this.nextCheckups[index].label
      }
      return value
    },

    appendFilter(field, value) {
      this.filters.fields[field] = value
      this.getCheckups()
    },

    appendDateFilter(field, value) {
      let start = new Date(value)
      start.setHours(0, 0, 0, 0)
      let end = new Date(value)
      end.setHours(23, 59, 59, 59)
      if (field === 'createdAt') {
        this.filters.startDate = start
        this.filters.endDate = end
      }
      this.getCheckups()
    },

    getCustomFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = '<input class="ag-input-field-input ag-text-field-input ' + params.className + '" type="text" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomSelectFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        let select = '<select class="ag-input-field-input ag-text-field-input ' + params.className + '">'
        select += '<option value=""></option>'
        if (params.options && params.options.length > 0) {
          params.options.map((item) => {
            select += `<option value="${item.value}">${item.label}</option>`
          })
        }
        select += '</select>'
        this.eGui.innerHTML = select
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('select')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomDateFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.className = 'w-100'
        this.eGui.innerHTML = '<input data-date-format="DD/MM/YYYY" class="ag-input-field-input ag-text-field-input ' + params.className + '" type="date" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendDateFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

  }

}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#tech-service-all {

  //.ag-header {
  //  min-height: inherit !important;
  //
  //  .ag-header-row-floating-filter {
  //    display: none !important;
  //  }
  //
  //}


  .tech-service-table {
    max-height: calc(var(--vh, 1vh) * 100 - 13rem);
    min-height: calc(var(--vh, 1vh) * 100 - 13rem);
    height: calc(var(--vh, 1vh) * 100 - 13rem);
    padding: 0.5rem;
  }

  .table-footer {
    padding-bottom: 10px !important;
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 768px) {
  [dir=ltr] .col-xl, [dir=ltr] .col-xl-auto, [dir=ltr] .col-xl-12, [dir=ltr] .col-xl-11, [dir=ltr] .col-xl-10, [dir=ltr] .col-xl-9, [dir=ltr] .col-xl-8, [dir=ltr] .col-xl-7, [dir=ltr] .col-xl-6, [dir=ltr] .col-xl-5, [dir=ltr] .col-xl-4, [dir=ltr] .col-xl-3, [dir=ltr] .col-xl-2, [dir=ltr] .col-xl-1, [dir=ltr] .col-lg, [dir=ltr] .col-lg-auto, [dir=ltr] .col-lg-12, [dir=ltr] .col-lg-11, [dir=ltr] .col-lg-10, [dir=ltr] .col-lg-9, [dir=ltr] .col-lg-8, [dir=ltr] .col-lg-7, [dir=ltr] .col-lg-6, [dir=ltr] .col-lg-5, [dir=ltr] .col-lg-4, [dir=ltr] .col-lg-3, [dir=ltr] .col-lg-2, [dir=ltr] .col-lg-1, [dir=ltr] .col-md, [dir=ltr] .col-md-auto, [dir=ltr] .col-md-12, [dir=ltr] .col-md-11, [dir=ltr] .col-md-10, [dir=ltr] .col-md-9, [dir=ltr] .col-md-8, [dir=ltr] .col-md-7, [dir=ltr] .col-md-6, [dir=ltr] .col-md-5, [dir=ltr] .col-md-4, [dir=ltr] .col-md-3, [dir=ltr] .col-md-2, [dir=ltr] .col-md-1, [dir=ltr] .col-sm, [dir=ltr] .col-sm-auto, [dir=ltr] .col-sm-12, [dir=ltr] .col-sm-11, [dir=ltr] .col-sm-10, [dir=ltr] .col-sm-9, [dir=ltr] .col-sm-8, [dir=ltr] .col-sm-7, [dir=ltr] .col-sm-6, [dir=ltr] .col-sm-5, [dir=ltr] .col-sm-4, [dir=ltr] .col-sm-3, [dir=ltr] .col-sm-2, [dir=ltr] .col-sm-1, [dir=ltr] .col, [dir=ltr] .col-auto, [dir=ltr] .col-12, [dir=ltr] .col-11, [dir=ltr] .col-10, [dir=ltr] .col-9, [dir=ltr] .col-8, [dir=ltr] .col-7, [dir=ltr] .col-6, [dir=ltr] .col-5, [dir=ltr] .col-4, [dir=ltr] .col-3, [dir=ltr] .col-2, [dir=ltr] .col-1 {
    padding-right: 0;
    padding-left: 0;
  }
}
.companyInfo {
  display: flex;
  flex-direction: column;
  .infoItem {
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    margin-bottom: 15px;
    .label {
      width: 50%;
    }
    .value {
      width: 50%;
      color: #000;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}
</style>
<style lang="scss">
.badgeColView {
  width: 55%!important;
}
</style>